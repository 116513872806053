<template>
  <div class="tabs-material-subtitle">
    <Container>
      <h2>Conditions particulières d'emploi des produits phytosanitaires</h2>
    </Container>
  </div>

  <div class="key-values">
    <KeyValue
      table
      label="Texte d'information"
      value="Les conditions et précautions d’emploi à prendre pour les produits phytopharmaceutiques
       sont celles indiquées sur les fiches de données de sécurité et dans les
       « Consignes de sécurité ».
       Rappel : le port des EPI réglementaires est obligatoire lors de toutes les
       phases de manipulation des produits, bouillies, rinçage du matériel, ..."
    />
    <KeyValue
      table
      :label="`L’utilisation de produits phytosanitaires issus du stock interne de
        l’organisme est tolérée, sous réserve de la bonne conservation du produit`"
      :value="protocol.utilisation_phyto"
    />
    <KeyValue table label="Mode d'application" :value="protocol.mode_appl_phyto"
    />
    <KeyValue table label="Volume de bouillie" :value="protocol.volume_bouillie"
    />
    <KeyValue table label="Observations" :value="protocol.observations"
    />
    <KeyValue table label="Contamination artificielle"
              :value="protocol.contamination_artificielle"
    />
    <KeyValue table label="Brumisation" :value="protocol.brumisation"
    />
    <KeyValue
      table
      label="Guidelines OEPP de référence"
      :value="protocol.guidelines_oepp_reference"
    />
    <KeyValue
      table
      label="Méthodes CEB de références"
      :value="protocol.methode_ceb_reference"
    />
    <KeyValue table label="Méthodes internes" :value="protocol.methodes_internes"
    />
  </div>

  <Section>
    <Container>
      <h2>Statut des produits non homologués à tester, vis à vis du permis d'expérimenter</h2>

      <Section v-for="(up, index) in unhomologedProducts" :key="index">
        <div class="key-values-title">
          <h3>Produit #{{index+1}}</h3>
        </div>
        <KeyValue table label="Produit" :value="up.produit.designation"/>
        <KeyValue
          table
          label="Dérogation à la destruction de récolte"
          :value="up.derogation_destruction_recolte ? 'Oui' : 'Non'"
        />
        <KeyValue
          table
          label="Permis d’expérimenter ou n° d’ADE"
          :value="up.permis_experimenter_ade"
        />
        <KeyValue
          table
          label="Réexpédition des produits au fabricant"
          :value="up.ref_expedition_produits_fabricant"
        />
      </Section>
    </Container>
  </Section>
  <!-- Mettre un KeyValue à trois entrées -->
</template>

<script>
import KeyValue from '@/components/form/KeyValue.vue'
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'

export default {
  name: 'ProtocolSubItemPhytosanitaryProducts',

  components: {
    Section,
    Container,
    KeyValue,
  },

  props: {
    pageTitle: {
      type: String,
    },
    protocol: {
      type: Object,
    },
  },

  data() {
    return {
      unhomologedProducts: [],
    }
  },

  async mounted() {
    this.unhomologedProducts = await this.getUnhomologedProducts()
  },

  methods: {
    async getUnhomologedProducts() {
      const response = await this.fetchService.get(
        `dictionnaire/statut/produit_non_homologue?filters=protocole.id:eq(${this.$route.params.id})`,
      )
      return response.data
    },
  },
}
</script>

<style scoped>
</style>
